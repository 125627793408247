.header {
    padding: 0 1rem;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    align-items: center;
  }
  
  .h-content {
    margin-top: 8rem ;
    margin-left: 6rem;
    font-size: 10px;
    font-weight: 200;
    color: var(--text-dark);
    padding: 20px 0;
  }
  
  .h-content h1 span {
    font-weight: 500;
    font-size: 70px;
   
   
  }
  
  .h-content p {
    margin: 25px 0;
    color: var(--text-light);
    line-height: .5rem;
    font-size: 30px;
  }
  
  .btn {
    border-radius: 20px;
    background-color: rgb(0, 64, 182);
  }

  .image {
    position: relative;
  display: flex;
  flex-direction: end;
  margin-top: 70px;
     
  }
  .image__bg
  { 
    margin-top: 50px;
    
    height: 460px;
    width: 650px;
    background:url(./img/b9.jpg);
    background-size: cover;
    mix-blend-mode: multiply;
  }
  .image__bg
  {
    transform: scale(1);
    transition: all 0.5s ease-in-out;
  }
  .image__bg:hover
  {
    transform: scale(1.1);
  }
  
   
    .card-container
    {
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      margin-top: 100px;
      height: 400px;
      gap: 50px 30px;
       
      
    }
  
    .card1
    {
      background-image: url(./img/b2.jpg);
      
      background-size: 100%;
      width: 325px;
      background-color: #333;
      color: #000000;
      border-radius: 30px;
      overflow: hidden;
      text-align: center;
      box-shadow: 0px 2px 4px rgba(33, 91, 216, 0.836);
      margin: 20px;
      
    }

    
    .card2
    {
      background-image: url(./img/b3.jpg);
      background-size: cover;
      width: 325px;
      background-color: #333;
      color: #000000;
      border-radius: 30px;
      overflow: hidden;
      text-align: center;
      box-shadow: 0px 2px 4px rgba(33, 91, 216, 0.836);
      margin: 20px;
      
    }
   
    .card3
    {
     
      background-image:  url(./img/b4.jpg);
      background-size: cover;
      
      width: 325px;
      background-color: #333;
      color: #000000;
      border-radius: 30px;
      overflow: hidden;
      text-align: center;
      box-shadow: 0px 2px 4px rgba(33, 91, 216, 0.836);
      margin: 20px;
     
    }
   
    .card-content
    {
     
      padding: 16px;
    }
    .card-content a
    {
      text-decoration: none;
      color: #000000;
    }
  
  
   .card-content h3 {
  font-size: 28px;
  margin-bottom: 1px;
  height: 50px; /* Adjust height as needed */
  position: relative; /* Add position relative */
}

  
    /*card details*/
  
    .card-details
    {
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      margin-top: 20px;
      height: 180px;
      gap: 50px 30px;
    }
  
    .card4
    {
     
      width: 335px;
      background: linear-gradient(to right, #1f254d, #000000);
  
      color: #9e9e9e;
      border-radius: 50px;
      overflow: hidden;
      
      box-shadow: 0px 2px 4px rgba(33, 91, 216, 0.836);
      margin: 10px;
      padding: 10px;
    
  
      
    }
    .card5
    {
      
      width: 335px;
      background: linear-gradient(to right, #1f254d, #000000);
  
      color: #9e9e9e;
      border-radius: 50px;
      overflow: hidden;
      
      box-shadow: 0px 2px 4px rgba(33, 91, 216, 0.836);
      margin: 10px;
      padding: 10px;
    
    
    }
    .card6
    {
      width: 335px;
      background: linear-gradient(to right, #1f254d, #000000);
  
      color: #9e9e9e;
      border-radius: 50px;
      overflow: hidden;
      
      box-shadow: 0px 2px 4px rgba(33, 91, 216, 0.836);
      margin: 10px;
      padding: 10px;
    
    }
  
    .content
    {
      padding: 16px;
    }
  
    .content p
    {
      font-size: 20px;
      margin-bottom: 8px;
      justify-content: center;
    }
  
    /*FOOTER BELOW*/
  
    footer{
      width: 100%;
      position: relative;
     margin-top: 70px;
      background: linear-gradient(to right, #00093c, #000000);
      color: #fff;
      padding: 80px 0 30px;
      border-top-left-radius: 125px;
      font-size: 13px;
      line-height: 20px;
    }
  
    .row
    {
      width: 85%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
    }
  
    .col
    {
      flex-basis: 20%;
      padding: 10px;
    }
  .col p{
    font-size: 15px;
  }
    .col .logo{
      width: 60px;
      margin-bottom: 20px;
      font-size: 30px;
     
    }
    .col h2{
      width: fit-content;
      margin-bottom: 20px;
      position: relative;
      color: #fff;
    }
    .col a
    {
      color:#fff;
    }
    .email-id
    {
      width: fit-content;
      border-bottom: 1px solid #ccc;
      margin: 10px 0;
    }
  ul li{
    list-style: none;
    margin-bottom: 5px;
  }
  ul li a
    {
      text-decoration: none;
      border-bottom: 1px solid #ccc;
      color: #fff;
    }
    ul li a:hover
    {
      color: #195ff5;
    }
    
