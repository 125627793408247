/* pop.css */

.popup {
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  height: auto;
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  margin-left: 80px;
  align-items: center;
}

.popup-content {
  background-color: rgb(208, 208, 208);
  padding: 20px;
  border-radius: 5px;
  max-width: 400px; /* Adjust the width as needed */
  width: 100%;
  max-height: 600px; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
}

.popup-content h2 {
  margin-top: 0;
  text-align: center;
}

.popup-content label {
  display: block;
  margin-bottom: 5px;
}

.popup-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #f7f4f4;
  border-radius: 3px;
  box-sizing: border-box;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
}

.popup-content button:last-child {
  margin-right: 0;
}

/* Change scrollbar color to blue */
.popup-content::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.popup-content::-webkit-scrollbar-thumb {
  background-color: blue; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners */
}
