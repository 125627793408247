.greens {
    font-family: Arial, sans-serif;
    height:auto;
    margin-top: 20px;
    width: 870px;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  .table-container {
    max-height: 300px; /* Adjust the max-height as needed */
    overflow: auto;
   margin-bottom: 20px;
   
  }

  /* CSS */


  .teacher-image {
    width: 70px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th,
  table td {
    border: 1px solid #b9b9b9;
    padding: 8px;
    text-align: center;
  }
  
  table th {
    background-color: #f2f2f2;
  }
  
  /* Style for table rows on hover */
  table tbody tr:hover {
    background-color: #bbd1eb;
    cursor: pointer;
  }
  
  /* Style for the register button */
  table button {
    padding: 5px 10px;
    background-color: #5c64f3;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  table button:hover {
    background-color: #00b93e;
  }
  
