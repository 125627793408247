

.teabody
{
 
  display: flex;
  height: 50%;
 border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 30px;
  
  font-family: Arial, Helvetica, sans-serif;
}

.teacontainer
{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 2cm;
  max-width: 900px;
  width: 500vh;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
 padding: 40px;
  border-radius: 10px;
}

.teacontainer .title
{
  font-size: 17px;
  justify-content: center;
  font-weight: 500;
  position: relative;
  padding: 5px;
}

.teacontainer .title::before
{
  
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 900px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}

.teacontainer .title
{
  font-size: 17px;
  justify-content: center;
  font-weight: 500;
  position: relative;
  padding: 5px;
  color: #000000;
}

.teacontainer form .user-details
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0 12px 0;
  color:#000000;
}
form .user-details
{
  padding: 10px;
}
form .user-details .input-box
{
  margin-bottom: 10px;
  width: calc(100% / 2 - 20px);

}
/*below is for image container*/


/*above is for image container*/
.user-details .input-box .details
{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.user-details .input-box input

{
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 7px;
  border: 2px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.2s ease;
}

.user-details.input-box input:focus,
.user-details .input-box input:focus
{
  border-color: #9b59b6;
}

/*User addresee css*/
.teacontainer .address-title
{
  font-size: 17px;
  justify-content: center;
  font-weight: 500;
  position: relative;
  padding: 5px;
  color:#000000;
}

.teacontainer form .user-address
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0 12px 0;
  color:#000000;
}
form .user-address
{
  padding: 10px;
}
form .user-address .input-box
{
  margin-bottom: 10px;
  width: calc(100% / 2 - 20px);

}

.user-address .input-box .details
{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.user-address .input-box input

{
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 7px;
  border: 2px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.2s ease;
}

.user-address.input-box input:focus,
.user-address .input-box input:focus
{
  border-color: #9b59b6;
}

/*User require css*/



/*User checkbox css*/
.teacontainer form .user-checkbox
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0 12px 0;
  color:#000000;
}
form .user-checkbox
{
  padding: 10px;
}

form .user-checkbox .input-box
{
  margin-bottom: 10px;
}

/*User button css*/

.teacontainer  .button {
  text-align: center;
  display: flex;
  justify-content: end;
  padding: 20px 30px;
}

.teacontainer .button button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #ffffff; 
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
 
}


.teacontainer  .button button:hover {
  background-color: #01951a; 
  color: #fff;
}



.teacontainer  form .user-require
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0 12px 0;
}
form .user-require
{
  padding: 10px;
}
form .user-require .input-box
{
  margin-bottom: 10px;
  width: calc(100% / 2 - 20px);

}

.user-require .input-box .details
{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.user-require .input-box input

{
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 7px;
  border: 2px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.2s ease;
}

.user-require.input-box input:focus,
.user-require .input-box input:focus
{
  border-color: #9b59b6;
}

  /*IMAGE FILE */

  .input-box input[type="file"] {
  width: 120px; /* Adjust the width as needed */
  height: 180px; /* Adjust the height as needed */
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.input-box .custom-file-input {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px; /* Add margin between buttons */
}

.input-box .custom-file-input:hover {
  background-color: #159f10;
}

.input-box .preview-image {
  display: block;
  width: 120px; /* Adjust the width as needed */
  height: 180px; /* Adjust the height as needed */
  margin-top: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
}
    /*IMAGE FILE */
