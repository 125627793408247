.authbody {
  font-family: Arial, Helvetica, sans-serif;
background-color: #f2f2f2;
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
margin: 0;
}

.container {
background-color: #fff;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
padding: 20px;
width: 300px;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
}

h1 {
color: #333;
}

.welcome-container p {
font-size: 18px;
color: #333;
margin: 10px 0;
}

.button-container {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
margin-top: 20px;
}

.button-container button {
margin-top: 10px;
}

.input-container {
width: 100%;
max-width: 200px; /* Adjust the max-width as needed */
margin: 0 auto;
}

button {
background-color: #4caf50;
color: #fff;
border: none;
padding: 10px 15px;
border-radius: 4px;
cursor: pointer;
font-size: 16px;
}

button:hover {
background-color: #45a049;
}

label {
display: block;
margin-top: 10px;
text-align: left;
}

input {
width: 100%;
padding: 8px;
margin-top: 5px;
box-sizing: border-box;
}

.toggle-mode {
color: #333;
cursor: pointer;
text-decoration: underline;
margin-top: 10px;
font-size: 14px;
}
